<template>
  <hero-navigation>
    <div v-if="!overview" class="projects-nav-elements">
      <div class="projects-nav-elements">
        <router-link class="lead-center-overview-link" :to="{ name: 'lead-center-overview' }">
          <img style="width: 44px; height: 44px" src="/eye.png" />
        </router-link>
      </div>
      <platform-selector-part />
      <active-platform-part v-show="selectedPlatform._id !== 'all'" />
      <router-link v-show="selectedPlatform._id !== 'all'" class="button-outline-blue" to="/lead-center/settings" @click.native.prevent="$redirectTo('/lead-center/settings/platform')">
        Settings
      </router-link>
      <router-link class="button-outline-blue" to="/lead-center/activities">
        Activities
      </router-link>
      <router-link class="button-outline-blue" to="/lead-center/contacts">
        Contacts
      </router-link>
      <router-link v-show="selectedPlatform._id !== 'all'" class="button-outline-blue" to="/lead-center/kpi">
        Kpi
      </router-link>
      <router-link style="margin-left: auto" class="button-outline-blue" to="/lead-center/workflows">
        Workflows
      </router-link>
      <button class="button-outline-blue" style="margin: 0" v-b-toggle.sidebar-right>
        Logs
      </button>
      <b-sidebar id="sidebar-right" title="Logs" right shadow>
        <div class="px-3 py-2">
          <journal-component />
        </div>
      </b-sidebar>
    </div>
    <div v-else>
      <div class="projects-nav-elements">
        <router-link class="lead-center-overview-link" :to="{ name: 'lead-center-activities' }">
          <b-icon icon="arrow-left" class="nav-icon"></b-icon>
        </router-link>
        <div class="monitoring" v-if="monitoring">
          <div class="statuses">
            <div class="block">
              <strong>Process:</strong>
              <div>
                <div>New: {{ monitoring.activities.new }}</div>
                <div>Failed: {{ monitoring.activities.failed }}</div>
                <div>Completed: {{ monitoring.activities.process_complete }}</div>
              </div>
            </div>
            <div class="block">
              <strong>Transcript:</strong>
              <div>
                <div>Waiting: {{ monitoring.activities.waiting_transcript }}</div>
                <div>Failed: {{ monitoring.activities.failed_transcript }}</div>
              </div>
            </div>
          </div>
          <!-- <div class="errors" v-if="monitoring.platforms && monitoring.platforms.apps_with_errors">
            <div v-for="(error, index) in monitoring.platforms.apps_with_errors" :key="index" class="error">
              <div>Platform URL: {{ error.platform_url }}</div>
              <div>Key: {{ error.key }}</div>
              <div>Sync Error: {{ error.sync_error }}</div>
            </div>
          </div> -->


          <div v-if="monitoring.platforms && monitoring.platforms.apps_with_errors">
            <b-button variant="danger">Errors: {{ monitoring.platforms.apps_with_errors.length }}!</b-button>
          </div>
        </div>
      </div>
    </div>
  </hero-navigation>
</template>
<script>
import HeroNavigation from '../components/HeroNavigation.vue'
import { mapGetters } from 'vuex'
// Parts
import PlatformSelectorPart from '@/agGridV2/parts/platform-selector.part.vue'
import ActivePlatformPart from '@/agGridV2/parts/active-platform.part.vue'
import JournalComponent from '@/agGridV2/components/journal.component.vue'

export default {
  name: 'lead-center-navigation',
  data() {
    return {}
  },
  props: ['overview', 'monitoring'],
  computed: {
    selectedPlatform() {
      return this.getSelectedPlatform()
    }
  },
  methods: {
    ...mapGetters(['me', 'isAdmin', 'isManager', 'isManagerAndBigger', 'getSelectedPlatform']),
  },
  components: {
    HeroNavigation,
    PlatformSelectorPart,
    ActivePlatformPart,
    JournalComponent
  }
}
</script>

<style lang="scss" scoped>
.lead-center-overview-link {
  display: flex;
  align-items: center;
  font-size: 35px;
  font-weight: 600;
  color: #939eff !important;
}
.god-eye {
  transition: transform 0.2s, box-shadow 0.2s;
  border-radius: 50%;
}
.god-eye:hover {
  transform: scale(10);
  filter: drop-shadow(0 0 10px rgba(255, 165, 0, 0.6)) drop-shadow(0 0 20px rgba(255, 165, 0, 0.5)) drop-shadow(0 0 30px rgba(255, 165, 0, 0.4));
}
.monitoring {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}
.statuses {
  margin-left: 50px;
  gap: 10px;
  display: flex;
  flex-direction: column;
}
.statuses .block {
  display: flex;
  div {
    padding-left: 10px;
    display: flex;
    gap: 10px;
  }
}
.errors {
  display: flex;
  max-width: 500px;
  overflow-x: auto;
  scroll-snap-type: x mandatory;
  gap: 10px;
}
.error {
  flex: 0 0 100%;
  box-sizing: border-box;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
  background-color: #ffe6e6; /* Светло-красный цвет */
  scroll-snap-align: start;
}
</style>